@import "variables";

.TrueFalseExercise {
  .TrueFalseAnswersCategories {
    position: absolute;
    width: 100%;
    top: 4rem;

    .Card {
      flex-direction: column;
      margin-bottom: 2rem;

      table {
        font-size: 1.75rem;

        tr {
          td {
            padding: 1rem;
            background: lighten($color_main, 8%);
            border: 0.2rem solid $color_main;

            &:first-child {
              width: 24rem;
            }

            &:last-child {
              width: 36rem;
              min-height: 3rem;
              position: relative;

              .count {
                overflow: hidden;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $color_bright;
                background: $color_bright;
                transition: all 500ms ease-in-out;
              }
            }
          }

          &:nth-child(2) td .count {
            transition-delay: 500ms;
          }

          &:nth-child(3) td .count {
            transition-delay: 1000ms;
          }

          &:nth-child(4) td .count {
            transition-delay: 1500ms;
          }

          &:nth-child(5) td .count {
            transition-delay: 2000ms;
          }

          &:nth-child(6) td .count {
            transition-delay: 2500ms;
          }

          &:nth-child(7) td .count {
            transition-delay: 3000ms;
          }
        }
      }

      .summary-text {
        padding: 1rem;

        p {
          font-size: 1.5rem;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.vertical .TrueFalseExercise {
  .TrueFalseAnswersCategories {
    .Card {
      .summary-text {
        p {
          font-size: 1.8rem;
        }
      }
    }
  }
}
