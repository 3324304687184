@import '../../../css/common_vars';

.outer.quiz > .middle {
  background-color: #00A8AB;
}

.TilesExercise {
  .InstructionCard {
    .text {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .OldCard {
    margin-top: 0;
  }

  .points-bar-container {
    margin-bottom: 2em;
  }

  .question-container {
    .question-animation {
      width: 100%;
    }

    .question {
      margin-bottom: 1em;
      padding: 1em;
      font-size: 1em;

      p {
        font-size: 2em;
        margin: 0;
      }

      .count {
        font-size: 1em;
      }

      .content {
        animation: pop-out 750ms ease-in forwards;
        font-weight: 700;
      }
    }
  }

  .answers-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .AnswerCard {
      height: 10rem;
      width: 70%;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      .content {
        font-size: 1.75rem;
        box-sizing: border-box;
        padding: 0.5rem;
      }

      .feedback {
        i {
          font-size: 6rem;
        }

        .feedback-description {
          font-size: 2rem;

          h2 {
            font-size: 3rem;
          }
        }
      }
    }
  }

  .buttons-container {
    margin-top: 1em;

    .next-question {
      width: 40%;
      font-size: 0.8em;
    }
  }

  .container-next {
    position: absolute;
    width: 100%;
    bottom: 14rem;
  }
}

.horizontal {
  .TilesExercise {
    .answers-container {
      justify-content: space-around;

      .AnswerCard {
        height: 13rem;
        width: 49%;
        margin-bottom: 0;

        &:nth-child(2) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}