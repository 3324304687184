.CauldronExercise .CauldronAnswers {
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;

  .answer {
    padding: 1.25rem;
    width: 25rem;
    height: 5rem;

    text-align: center;

    margin-bottom: 1rem;

    font-size: 1.4rem;

    code {
      font-size: 1.5em;
      text-decoration: none;
      line-height: 1em;
      vertical-align: middle;
    }

    &.disabled {
      opacity: 0.6;
      cursor: auto;

      &.opaque {
        opacity: 0;
      }
    }
  }
}

.vertical .CauldronExercise .CauldronAnswers {
  .answer {
    width: 16rem;
    height: 8rem;

    font-size: 1.5rem;
  }
}