@import 'variables';

$filler-length: 40rem;
$bar-height: 6rem;
$bar-color: $color_bright;
$text-color: $color_main;

@keyframes animation-clock-warning-rotate {
  0% {
    transform: rotateZ(0deg) scale3d(1, 1, 1);
  }
  25% {
    transform: rotateZ(90deg) scale3d(1.25, 1.25, 1);
  }
  50% {
    transform: rotateZ(180deg) scale3d(1, 1, 1);
  }
  75% {
    transform: rotateZ(270deg) scale3d(1.25, 1.25, 1);
  }
  100% {
    transform: rotateZ(360deg) scale3d(1, 1, 1);
  }
}

.PointsBar {
  padding: 1rem 1rem 1.5rem;
  height: $bar-height;
  color: $text-color;

  position: relative;
  z-index: 1;

  .left-filler {
    position: absolute;
    height: $bar-height;
    width: $filler-length + 1rem;
    left: -$filler-length;

    background-color: $bar-color;
  }

  .points-bar-body {
    background-color: $bar-color;
    position: relative;
    height: 100%;
    width: 100%;
    border-top-right-radius: $bar-height / 2;
    border-bottom-right-radius: $bar-height / 2;
    font-size: 2rem;
    font-weight: 700;

    .points-bar-with-icon {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .points {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .current-points {
          font-size: 3rem;
          line-height: 3rem;
        }

        .max-points {
          color: tint($text-color, 15%);
          line-height: 2rem;
        }
      }
    }

    .points-bar-content {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      font-size: 1.5rem;

      display: inline-flex;
      justify-content: center;
      align-items: center;

      padding-left: 2rem;
      padding-right: 2rem;
    }

    &.with-cup .points-bar-content {
      padding-left: $bar-height + 7.5rem;
    }

    &.with-clock .points-bar-content {
      padding-right: $bar-height + 7.5rem;
    }

    .points-bar-with-icon {
      display: flex;
      align-items: center;

      position: absolute;
      width: 7rem;
      height: $bar-height + 1rem;
      top: -0.5rem;

      background-repeat: no-repeat;
    }

    .cup-icon {
      min-width: 7rem;
      min-height: 7rem;

      justify-content: flex-start;

      * {
        fill: $color_main !important;
      }
    }

    .clock {
      right: -0.5rem;
      //background-image: url('./images/clock-frame.svg');
      //background-position: right;
      width: 6.5rem;
      padding-right: $bar-height + 2rem;

      justify-content: flex-end;

      .clock-frame, .clock-hands {
        content: ' ';
        position: absolute;
        min-width: 7rem;
        min-height: 7rem;
        right: -4rem;
        top: 0;

        * {
          fill: $color_main !important;
        }
      }

      &.clock-warning {
        color: darken($color_error, 15%);

        .clock-frame {
          animation: animation-clock-warning-rotate 2s linear 0s infinite normal backwards running;
        }
        .clock-hands {
          animation: animation-clock-warning-rotate 1s linear 0s infinite normal backwards running;
        }

        &.clock-warning-stopped {
          &::before {
            animation-play-state: paused;
          }
          &::after {
            animation-play-state: paused;
          }
        }
      }
    }
  }
}