@import "css/common_vars";

.CauldronExercise .Cauldron {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 7rem;

  &.exploding {
    .cauldron-container {
      animation: cauldron-hide 1 ease-in-out 3000ms forwards;

      .image {
        animation: cauldron-explosion 1 ease-in-out 3000ms forwards;

        #Cauldron {
          path {
            transition: fill 3000ms ease-in;
            fill: $color_error !important;
          }
        }
      }
    }
  }

  &.brewed {
    .cauldron-container {
      .image {
        #Ooze {
          path {
            fill: $color_success !important;
            transition: fill 2000ms ease-in;
          }
        }
      }

      .bubbles {
        transition: all 500ms ease-in-out;
        opacity: 0;
      }
      //
      //.bubble {
      //  transition: all 2000ms ease-in;
      //  border-color: $color_success;
      //}

      .star {
        animation-play-state: running;
        animation-iteration-count: infinite;
      }
    }
  }

  .cauldron-container {
    position: relative;
    z-index: -1;

    .cauldron-svg {
      position: relative;
      z-index: 0;
    }

    .image {
      z-index: -1;
      width: 20rem;
    }

    .bubble, .star {
      position: absolute;
      top: 0.25rem;
    }

    .bubbles {
      z-index: -2;

      .bubble {
        border: solid $color_accent;
        border-radius: 50%;
        box-sizing: border-box;

        animation-iteration-count: infinite;

        z-index: -2;

        &.bubble-1 {
          $size: 3rem;

          left: 3rem;
          width: $size;
          height: $size;
          border-width: $size / 2 + 0.1rem;

          animation-name: bubble-animation-1;
          animation-duration: 2.5s;
        }

        &.bubble-2 {
          $size: 5rem;

          left: 8rem;
          width: $size;
          height: $size;
          border-width: $size / 2 + 0.1rem;

          animation-name: bubble-animation-2;
          animation-duration: 3s;
        }

        &.bubble-3 {
          $size: 4rem;

          left: 14rem;
          width: $size;
          height: $size;
          border-width: $size / 2 + 0.1rem;

          animation-name: bubble-animation-3;
          animation-duration: 2s;
        }
      }
    }

    .star {
      color: $color_success;

      font-size: 3rem;
      z-index: -1;
      animation-play-state: paused;

      &.star-1 {
        animation-name: star-animation-1;
        animation-duration: 1s;
      }

      &.star-2 {
        animation-name: star-animation-2;
        animation-duration: 1.2s;
      }

      &.star-3 {
        animation-name: star-animation-3;
        animation-duration: 0.9s;
      }

      &.star-4 {
        animation-name: star-animation-4;
        animation-duration:  1.1s;
      }

      &.star-5 {
        animation-name: star-animation-5;
        animation-duration:  0.8s;
      }

    }
  }
}

.vertical .CauldronExercise .Cauldron {
  margin-top: 8.5rem;
  .cauldron-container .image {
    width: 22rem;
  }
}

$fly-info: -8rem 1.6rem, -5rem 2.6rem, -7rem 2.1rem;
@for $i from 1 through length($fly-info) {
  $info: nth($fly-info, $i);
  $height: nth($info, 1);
  $border: nth($info, 2);

  @keyframes bubble-animation-#{$i} {
    0% {
      opacity: 1;
      transform: translateY(0);
      animation-timing-function: ease-in;
    }

    85% {
      opacity: 1;
      transform: translateY($height);
      border-width: $border;
      animation-timing-function: ease-in-out;
    }

    100% {
      opacity: 0;
      transform: translateY($height);
      border-width: 0;
      animation-timing-function: ease-in-out;
    }
  }
}

$star-animation-info:
        2rem -8rem -2rem 2.5rem 720deg,
        5rem -9rem 1rem 3rem 720deg,
        7rem -7rem 2rem 3rem 720deg,
        11rem -10rem -1rem 2.5rem 720deg,
        14rem -8rem 1rem 3.5rem 720deg;
@for $j from 1 through length($star-animation-info) {
  $info: nth($star-animation-info, $j);
  $left: nth($info, 1);
  $y-transform: nth($info, 2);
  $x-transform: nth($info, 3);
  $size: nth($info, 4);
  $rotation: nth($info, 5);

  @keyframes star-animation-#{$j} {
    0% {
      left: $left;
      opacity: 1;
      font-size: $size;
      transform:
              translateY(0)
              translateX(0)
              rotate(0deg);
      animation-timing-function: ease-in;
    }

    80% {
      left: $left;
      opacity: 1;
      font-size: $size;
      transform:
              translateY(($y-transform * 4) / 5)
              translateX(($x-transform * 4) / 5)
              rotate(($rotation * 4) / 5);
      animation-timing-function: ease-in-out;
    }

    100% {
      left: $left;
      opacity: 0;
      font-size: $size;
      transform:
              translateY($y-transform)
              translateX($x-transform)
              rotate($rotation);
      animation-timing-function: ease-in-out;
    }
  }
}

@keyframes cauldron-explosion {
  0% {
    transform: scale(1);
  }

  90% {
    transform: scale(1);
    transform-origin: center;
  }

  100% {
    transform: scale(5);
    transform-origin: center;
  }
}

@keyframes cauldron-hide {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}