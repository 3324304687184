@import "variables";

$side-border: 5px solid shade($color_background, 15%);

@keyframes loading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Sprint {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  height: 100%;
  //background: shade($color_background, 25%);
  background: $color_background;

  padding-bottom: 40px;
  box-sizing: border-box;
  z-index: 0;

  &:after {
    content: " ";
    display: block;
    z-index: -1;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: $background-main-image no-repeat;
    background-position: $background-main-position;
    background-size: $background-main-size;
    // background: url(../images/logo-ge-white.svg) no-repeat;
    //background-position-x: 2em;
    //background-position-y: 5em;
    //background-size: 50%;

    opacity: 0.2;
  }

  &.loading .loading-bar {
    height: 40px;
    width: 100%;

    position: fixed;
    left: 0;
    bottom: 0;

    background: white;

    animation: loading ease-in-out 1s alternate infinite;
  }

  .SprintArea {
    position: relative;

    box-sizing: border-box;
    margin: 0;
    z-index: 0;

    .side {
      position: absolute;
      background: $color_background;
      box-sizing: border-box;

      transition: opacity 500ms ease-in-out;
      opacity: 0;

      z-index: 100;

      &.show {
        opacity: 1;
      }

      &.left,
      &.right {
        height: 100vh;
        width: 50vw;
      }

      &.top,
      &.bottom {
        height: 50vh;
        width: 100vw;
      }

      &.left {
        left: -50vw;
        border-right: $side-border;
      }

      &.right {
        right: -50vw;
        border-left: $side-border;
      }

      &.top {
        top: -50vh;
        border-bottom: $side-border;
      }

      &.bottom {
        bottom: -50vh;
        border-top: $side-border;
      }
    }

    .inner-sprint-area {
      position: relative;
      width: 100%;
      height: 100%;

      &:after {
        content: " ";
        display: block;
        z-index: -1;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: $background-image no-repeat;
        background-position: $background-position;
        background-size: $background-size;
        // background: url(../images/logo-ge-white.svg) no-repeat;
        //background-position-x: 2em;
        //background-position-y: 5em;
        //background-size: 50%;

        opacity: 0.2;
      }
    }
  }

  .consent-overlay {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #000000dd;
  }

  .CookieConsent {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    right: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0;
    padding: 0.5rem 0;
    background: $color_bright;

    .content {
      color: $color_main;
      padding: 0.5rem 1.5rem;
      font-size: 1.25rem;
      flex-grow: 1;
    }

    button {
      color: white;
      border: none;
      padding: 1rem 2rem;
      margin: 0.5rem 1rem;
      font-size: 1.5rem;
      font-weight: 700;
      background: $color_clickable;

      &:hover {
        background: $color_clickable_hover;
      }
    }

    & > * {
      z-index: 1;
    }

    &.non-invasive {
      opacity: 0.8;
      width: 50%;
      right: 1rem;
      bottom: 1rem;

      .content {
        font-size: 1rem;
      }

      button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
      }
    }
  }
}
