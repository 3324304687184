@import '../../../css/common_vars';

.ElevatorButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  padding: 2rem 1rem 0 1rem;

  .Button {
    width: 5rem;
    height: 5rem;

    margin-bottom: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: $color_clickable 0.5rem solid;
    transition: all 500ms ease-in-out;

    &.selected {
      border: white 0.5rem solid;
    }

    &.next-floor {
      border-radius: 5rem;
      width: 10rem;

      .svg-inline--fa {
        margin-right: 0.75rem;
      }
    }
  }

  &.floors-disabled {
    background-color: $color_main;

    .Button.disabled {
      opacity: 1;
      border-color: darken($color_main, 10);
      background-color: darken($color_main, 10);

      &.selected {
        border-color: white;
      }
    }
  }
}

.vertical .ElevatorButtons {
  justify-content: center;
}