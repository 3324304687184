@keyframes pop-out {
  0% {
    transform: scale(0.5);
    animation-timing-function: ease-in-out; }
  60% {
    transform: scale(1.3);
    animation-timing-function: ease-in-out; }
  80% {
    transform: scale(0.7);
    animation-timing-function: ease-in-out; }
  100% {
    transform: scale(1);
    animation-timing-function: ease-in-out; } }

.OverlayMessage .cross-fade-leave {
  opacity: 1; }

.OverlayMessage .cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 0.25s ease-in; }

.OverlayMessage .cross-fade-enter {
  opacity: 0; }

.OverlayMessage .cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in; }

.OverlayMessage .cross-fade-height {
  transition: height .5s ease-in-out; }

.OverlayMessage .OldCard {
  position: absolute;
  left: 5%;
  width: 90%;
  padding: 1.5em;
  top: 0;
  z-index: 2005;
  background: #00c618;
  color: white; }
  .OverlayMessage .OldCard span {
    animation: pop-out 1000ms ease-in forwards;
    font-size: 1.25em; }

.OverlayMessage .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background-color: rgba(0, 0, 0, 0.5); }
