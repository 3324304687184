@import '../../../css/common_vars';

.ElevatorDoors {
  transition: transform ease-in-out; // Duration set in JS
  height: 100%;

  position: absolute;
  top: 0;

  $elevator-border-width: 0.5rem;
  $elevator-border: $elevator-border-width solid $color_bright;

  .shaft {
    position: absolute;
    top: 0;
    height: 100%;
    border-left: $elevator-border;
    border-right: $elevator-border;
    box-sizing: border-box;
    z-index: -1;

    opacity: 0.7;
  }

  .choose-answer {
    position: absolute;
    top: 50em;
    width: 100%;
  }
}

