.fade-enter {
  opacity: 0.01; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-leave {
  opacity: 1; }

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.fade-appear {
  opacity: 0.01; }

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.move-up-appear {
  bottom: 0; }
  .move-up-appear.move-up-appear-active {
    top: 100%;
    transform: translateY(-100%); }
