@import "variables";

$bar-height: 32px;

.SprintBar {
  height: 40px;
  width: 100%;

  position: fixed;
  left: 0;
  bottom: 0;

  box-sizing: border-box;
  padding: 0 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: $color_main;
  background: white;

  font-size: 16px;
  font-weight: 500;

  & > * {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  .logo {
    box-sizing: border-box;
    padding: 4px 0;

    .logo-svg {
      width: $bar-height;
      height: $bar-height;

      svg g > * {
        fill: $color_main !important;
      }
    }
  }

  .points .value {
    font-weight: 700;
    white-space: nowrap;
  }

  @media (max-width: 220px) {
    display: none;
  }

  @media (max-width: 360px) {
    .logo {
      display: none;
    }

    .levels {
      justify-content: flex-end;
    }
  }
}