.MatchExercise {
  position: relative;
  width: 100%;
  height: 100%;

  .exercise-container {
    position: relative;

    .next-question {
      position: absolute;
      top: 10rem;
      width: 100%;
    }
  }
}