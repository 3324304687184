.CauldronExercise {
  position: relative;
  width: 100%;
  height: 100%;

  .exercise-container {
    .finish-feedback-container {
      position: relative;

      .finish-feedback {
        position: absolute;
        top: 1rem;
        left: 0;
        z-index: 10;

        width: 100%;
        padding: 2rem 0;
        font-size: 2rem;
      }
    }

    .next-question {
      position: absolute;
      top: 10rem;
      width: 100%;
    }
  }
}