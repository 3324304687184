.SprintArea.parachutes {
  .side.top, .side.bottom {
    opacity: 1;
  }
}

.ParachutesExercise {
  .PointsBar {
    z-index: 0 !important;
  }
}