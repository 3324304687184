@import '../../variables';
@import '../../css/animations';

.ShootingExercise {
  .buttons-container .button p {
    border-radius: 0.2em;
  }

  .OverlayMessage {
    font-size: 1.25em;
  }

  .question {
    min-height: 5em;
    display: flex;
    justify-content: center;
    flex-direction: column;

    background: #d91b5c;
    color: #fff;
    font-size: 2em;
    margin: 0;
    padding: 0.5em;

    white-space: pre-line;

    p, blockquote {
      animation: pop-out-small 750ms ease-in forwards;
    }

    p {
      margin: 0.25em 0;
    }

    blockquote {
      margin: 0;
      font-size: 0.9em;
    }
  }

  .answers-container {
    position: relative;
    margin-top: -50rem;

    z-index: 5;
  }

  .summary {
    padding: 1em;
    max-height: 38em;
    overflow-y: auto;

    margin-top: 5em;
    z-index: 10;

    h1, h2, li {
      margin: 0;
    }

    h1 {
      font-size: 2.25em;
      padding: 0.5em 0 0;
      margin: 0;
    }

    h2 {
      font-size: 1.5em;
      padding: 0.75em 0 0.25em;
    }

    li {
      font-size: 1.5em;
      list-style: square;
    }

    table {
      .category-name {
        font-size: 1.5em;
        text-align: right;
        padding: 0.5em;

        width: 1%;
        white-space: nowrap;
      }
      .category-points {
        width: 100%;

        .category-percentage-container {
          height: 1.5em;
          border: 1px solid black;

          .category-percentage {
            height: 1em;
            padding: 0.25em;

            background-color: $color_accent;
          }
        }
      }
    }
  }

  .buttons-container {
    margin-top: 5em;

    &.skip {
      margin-top: -59em;
      text-align: right;

      .OldCard.button {
        z-index: 0;
        width: 25%;
        text-align: center;

        p {
          font-size: 1.5em;
        }
      }
    }

    &.start {
      width: 100%;
      margin-top: 18em;
      text-align: center;
    }

    div {
      z-index: 2000;
    }

    .OldCard.button {
      position: relative;
    }
  }
}

.vertical .ShootingExercise {
  .PointsBar .OldCard.instruction {
    font-size: 2.5em;
    display: flex;
    height: 2.8em;
    max-width: 15em;
    justify-content: center;
    align-items: center;

    div {
      line-height: inherit;
    }
  }

  .question {
    min-height: 7em;
  }

  .summary {
    max-height: 50em;
  }

  .buttons-container.skip {
    margin-top: -68em;
  }
}