.SprintArea.elevator {
   .side.top, .side.bottom {
    opacity: 1;
  }
}

.ElevatorExercise {
  .answers {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .ElevatorButtons {
      width: 20%;
    }

    .ElevatorDoors {
      position: absolute;
      //margin-left: 40rem;
      //width: 30rem;
    }

  }

  .fixed {
    width: 100%;
    position: absolute;
    bottom: 5rem;
  }

  .last-chance {
    padding: 2rem 1rem;
    font-size: 2.5rem;
    font-weight: 700;
  }

  .out-of-time {
    width: auto;
    padding: 1rem 10rem;
    margin-bottom: 3rem;
  }
}