.MatchExercise .MatchAnswers {
  display: flex;
  justify-content: space-around;

  flex-wrap: wrap;

  &.three-cols {
    padding: 0 8rem;
  }

  .answer {
    width: 19rem;
    height: 7.5rem;

    text-align: center;

    margin-bottom: 1rem;

    font-size: 1.5rem;

    flex-direction: column;
    p {
      width: 100%;
      margin: 0 0 0.25rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.long {
      height: 13rem;
    }

    &.very-long {
      font-size: 1.25rem;
    }

    &.disabled {
      opacity: 0.6;
      cursor: auto;

      &.opaque {
        opacity: 0;
      }
    }
  }
}

.vertical .MatchExercise .MatchAnswers {
  &.three-cols {
    padding: 0;
  }

  .answer {
    width: 24rem;

    &.small {
      height: 6.5rem;
    }

    &.long {
      height: 12rem;
    }
  }
}
