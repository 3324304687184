@import '../../css/common_vars';

.SendResultsScreen {
  padding-top: 10rem;
  .message {
    height: 10rem;
    margin-bottom: 2rem;

    transition: all 500ms;

    &.error {
      background: $color_error;
      color: white;
    }

    &.success {
      background: $color_success;
      color: white;
    }

    .content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
      box-sizing: border-box;

      .fa-spinner {
        margin-right: 0.25em;
      }

      p {
        font-size: 1.5em;
      }
    }
  }
}