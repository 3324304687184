@import '../../css/common_vars';

.ChoicesExercise {
  .answers {
    padding: 0 0.5rem 0 1rem;
    margin-bottom: 2rem;

    height: 34rem;
    overflow-y: scroll;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .Card {
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      width: 49%;
      margin-bottom: 1rem;
      font-size: 2rem;

      user-select: none;

      transition: all 200ms ease-in-out;

      &.selected {
        color: white;
      }

      &.disabled {
        opacity: 0.6;
      }
    }
  }

  .email-info {
    .Card.email-form {
      flex-wrap: wrap;
      margin-bottom: 2rem;

      padding: 3rem 1rem;

      .message {
        width: 0;
        height: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        opacity: 0;
        transition: all 300ms ease-in-out;

        &.error {
          background-color: $color_error;
        }

        &.success {
          background-color: $color_success;
        }

        &.visible {
          width: 40rem;
          height: 7rem;
          opacity: 1;

          font-size: 1.25rem;
          margin-bottom: 1rem;
          padding: 1rem;

          overflow: hidden;
        }
      }

      label {
        font-size: 1.25rem;
        width: 100%;
        display: block;
        margin-bottom: 1rem;
      }

      input, textarea {
        width: 30rem;
        padding: 1rem;

        font-size: 1.25rem;
        margin-bottom: 3rem;
      }

      textarea {
        height: 8rem;
      }

      *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .send-button {
    width: 20rem;

    .icon {
      margin-right: 1rem;
    }
  }
}

.vertical {
  .ChoicesExercise {
    .answers {
      height: 48rem;

      .Card {
        width: 100%;
      }
    }
  }
}