@import '../../../css/common_vars';

$roomOverflowWidth: 20rem;

$roomBorderWidth: 0.5rem;
$roomBorder: solid white $roomBorderWidth;
$roomLightsOut: $color_main;

$wallHeight: 20rem;
$floorHeight: 10rem;

.DoorsExercise .Room {
  height: $wallHeight + $floorHeight;

  border-top: $roomBorder;
  border-bottom: $roomBorder;

  &, .Wall, .Floor {
    position: relative;

    width: 100%;
    margin-left: -50%;
    padding: 0 50%;
  }

  .Darkness {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;

    pointer-events: none;

    background-color: $roomLightsOut;

    transition: opacity 500ms ease-in-out;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  .Wall {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: $wallHeight;

    background-color: $color_accent;

    .wallpaper {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 0;

      opacity: 0.3;
    }

    .Door {
      position: relative;
      align-self: flex-end;

      height: 16rem;
      width: 10rem;
      margin-left: 3rem;

      display: inline-block;

      border-color: white;
      border-width: 1rem;
      border-top-style: solid;
      border-left-style: solid;
      border-right-style: solid;
      background-color: black;

      z-index: 1;

      .door {
        z-index: 1;

        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $color_main;

        &.active {
          cursor: pointer;
        }

        .handle {
          position: absolute;

          top: 45%;
          right: 1.5em;

          width: 1.5em;
          height: 1.5em;

          border-radius: 50%;

          background-color: white;
        }

        transition: transform ease-in-out 0;
        transition-delay: 200ms;
        transform-origin: left center;

        &.open {
          transform: perspective(15rem) rotateY(90deg);
          transition-duration: 1000ms;
        }

        &.shaking {
          animation: shake 500ms cubic-bezier(.36,.07,.19,.97) both;
          transform-origin: center;
          backface-visibility: hidden;
        }
      }

      .inside {
        position: absolute;
        width: 100%;
        height: 100%;

        background-color: $color_success;

        z-index: 0;

        .sky {
          position: absolute;
          width: 100%;
          height: 75%;

          overflow: hidden;

          background-color: #85B3D6;

          .sun {
            position: absolute;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            top: -1.5rem;
            right: -1.5rem;

            background-color: #FFFA98;
          }
        }
      }
    }

    .Keys {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 48rem;
      height: 14rem;
      margin-right: 1rem;
      //background-color: lighten($color_accent, 20);
      background-color: #FFFFFF77;

      z-index: 1;

      .Key {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        opacity: 0.5;

        transition: transform 500ms ease-in-out;

        &.active {
          cursor: pointer;
          opacity: 1;
        }

        &.chosen {
          cursor: default;
          transform: scale(1.5);
        }

        svg {
          path.outline {
            fill: white;
          }

          path.fill {
            fill: $color_clickable;
          }
        }
      }
    }
  }

  .Floor {
    display: flex;
    justify-content: center;
    align-items: center;

    height: $floorHeight;

    background-color: darken($color_accent, 20);

    .Answer {
      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0 1rem;
      width: 100%;
      height: 10rem;

      color: white;

      font-size: 2rem;
    }
  }
}

.vertical .DoorsExercise .Room {
  height: 50rem;

  .Wall {
    height: 30rem;

    .Door {
      height: 25rem;
      width: 15rem;
    }

    .Keys {
      top: 3rem;
      right: 26rem;

      width: 25rem;
      height: 25rem;

      flex-wrap: wrap;
      justify-content: space-around;

      .Key {
        width: 35%;
        height: 40%;

        &.chosen {
          transform: scale(1.5);
        }
      }
    }
  }

  .Floor {
    height: 20rem;

    .Answer {
      height: 15rem;
    }
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-0.2rem, 0, 0) rotate(1deg);
    transform-origin: center;
  }

  20%, 80% {
    transform: translate3d(0.2rem, 0, 0) rotate(-1deg);
    transform-origin: center;
  }

  30%, 50%, 70% {
    transform: translate3d(-0.4rem, 0, 0) rotate(2deg);
    transform-origin: center;
  }

  40%, 60% {
    transform: translate3d(0.4rem, 0, 0) rotate(-2deg);
    transform-origin: center;
  }
}

