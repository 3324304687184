.MatchExercise .MatchCategories {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  &.no-question {
    margin-top: 3rem;
  }

  .category {
    position: relative;

    padding: 0;
    flex-direction: column;
    margin-bottom: 1rem;

    width: 24rem;
    height: 16rem;

    &.small {
      width: 20rem;
      height: 13.3rem;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-size: cover;
      background-position: center;
    }

    .name {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: rgba(1, 1, 1, 0.6);

      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 0.75rem;

      position: absolute;
      top: 0;
      left: 0;
      font-size: 2rem;
    }
  }
}

.vertical .MatchExercise .MatchCategories {
  .category {
    width: 24rem;
    height: 16rem;

    &.small {
      width: 21rem;
      height: 14rem;
    }
  }
}
