@import '../../css/common_vars';

.FeedbackCard {
  position: absolute;
  bottom: 6rem;

  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  user-select: none;

  & > * {
    z-index: 101;
  }

  .feedback {
    position: relative;
    text-align: center;

    color: #FFFFFF;
    background-color: $color_error;

    width: 100%;
    padding: 2rem;
    font-size: 2rem;
    box-sizing: border-box;

    & > * {
      margin: 0 0 0.5rem 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul, ol {
      text-align: left;
    }

    &.success {
      background-color: $color_success;
    }
  }

  .button-container {
    margin-top: 2rem;
  }
}