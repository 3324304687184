$background-main-image: url(../themes/example/bg-2.svg);
$background-main-position: center;
$background-main-size: cover;

$background-image: none;
$background-position: center;
$background-size: cover;

$color_background: #557ebe;

$color_bright: #f7ad19; //#00F0AA;
$color_main: #104ba9;
$color_accent: #ff6700;

$color_highlight: rgb(255, 128, 0);

$color_clickable: #d35400;

$color_success: #08a000;
$color_error: #c2000a;

$main-font-family: "Lato";
