.ParachutesCategories {
  width: 100%;
  display: flex;

  position: absolute;
  bottom: 6rem;

  justify-content: space-evenly;

  .category {
    width: 100%;
    margin-right: 0.5rem;
    height: 6rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-right: 0;
    }
  }
}