@import '../../css/common_vars';

.SprintArea.rise_fall {
  .side.top, .side.bottom {
    opacity: 1;
  }
}

.outer.rise_fall > .middle {
  background-color: #00A8AB;
}

.RiseFallExercise {
  height: 100%;

  .cloud {
    position: absolute;
    width: 20em;
    opacity: 0.8;
  }

  #cloud-1 {
    top: 7em;
    left: -20em;
  }

  #cloud-2 {
    top: 28em;
    left: -60em;
  }

  #cloud-3 {
    top: 50em;
    left: -40em;
  }

  .container-next {
    margin-top: 5rem;
  }

  .points-bar-container {
    height: auto;
    position: relative;
    z-index: 20;
  }

  .buttons-container {
    margin-top: -60em;

    &.start-button {
      position: relative;
      width: 100%;
      margin-top: 18em;
      text-align: center;

      p {
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }

  .question-container {
    .question {
      background: $color_accent;
      color: white;

      margin-top: 0;
      z-index: 20;
      font-size: 2.25em;
      padding: 0.5em;

      p {
        font-weight: 700;
        animation: pop-out 750ms ease-in forwards;

        &.question-count {
          margin-bottom: 0;
          font-size: 0.75em;
          font-weight: 400;
        }

        &.question-content {
          margin-top: 0;
        }
      }
    }
  }

  .feedback-container {
    width: 100%;
    position: absolute;
    bottom: 10em;
    z-index: 100;

    .feedback-overlay {
      z-index: 23;

      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: rgba(0, 0, 0, 0.5);
    }

    .feedback {
      z-index: 25;
      font-size: 1.5em;
      padding: 0 1em;

      &.success {
        background: $color_success;
        color: white;
      }

      &.failure {
        background: $color_error;
        color: white;
      }
    }
  }

  .answers-container {
    // height set in JS
    margin: -20em 0 1em 0;
    position: relative;
    z-index: 10;

    .timeFinished {
      z-index: 10;

      position: absolute;
      width: 60%;
      left: 20%;
      top: 5%;

      h1 {
        padding: 3em;
      }
    }
  }

  .summary-container {
    margin-top: -50em;

    div {
      z-index: 20;
    }

    .summary {
      padding: 1em;
      h1 {
        margin-bottom: 1em;
      }

      p {
        font-size: 1.5em;
      }
    }
  }

  .buttons-container div {
    z-index: 2000;
  }

  &.tutorial {
    .OverlayMessage {
      font-size: 1.25em;

      .OldCard {
        width: 100%;
        left: 0;
        margin: 0;
      }
    }

    .question {
      margin-top: 1.5em;
      z-index: 2000;
    }

    .answers-container {
      z-index: auto;

      .attachment {
        top: -16em;
      }
    }

    //.feedback-container {
    //  z-index: 2000;
    //}
  }

}

.vertical {
  .RiseFallExercise {
    .PointsBar {
      .instruction {
        display: flex;
        align-items: center;

        height: 3.5em;
      }
    }

    .summary-container {
      margin-top: -80em;
    }
  }
}