.ChangingText {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 500ms ease-in-out;

  opacity: 1;

  &.hiding {
    opacity: 0;
  }
}