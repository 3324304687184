$scale: 4rem;
$durationScale: 1000ms;

@keyframes animation-incorrect-explosion-circle {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 1);
  }
  12.5% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  75% {
    opacity: 1;
    transform: scale3d(2.6, 2.6, 1);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 1);
  }
}

@keyframes animation-incorrect-explosion-x-1 {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale3d(0.1, 0.1, 1);
  }
  20% {
    opacity: 1;
    transform: translate3d(2*$scale, -2*$scale, 0) scale3d(0.5, 0.5, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(5*$scale, -5*$scale, 0) scale3d(0.1, 0.1, 1);
  }
}

@keyframes animation-incorrect-explosion-x-3 {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale3d(0.1, 0.1, 1);
  }
  28.5714% {
    opacity: 1;
    transform: translate3d(-2*$scale, 2*$scale, 0) scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(-5*$scale, 5*$scale, 0) scale3d(0.3, 0.3, 1);
  }
}

@keyframes animation-incorrect-explosion-x-4 {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale3d(0.1, 0.1, 1);
  }
  40% {
    opacity: 1;
    transform: translate3d(2*$scale, 2*$scale, 0) scale3d(0.3, 0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(5*$scale, 5*$scale, 0) scale3d(0.2, 0.3, 1);
  }
}

@keyframes animation-incorrect-explosion-x-2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale3d(0.1, 0.1, 1);
  }
  50% {
    opacity: 1;
    transform: translate3d(-2*$scale, -2*$scale, 0) scale3d(0.4, 0.4, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(-5*$scale, -5*$scale, 0) scale3d(0.2, 0.2, 1);
  }
}

@keyframes animation-incorrect-explosion-skull-1 {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale3d(0.001, 0.001, 1);
  }
  16.6667% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1);
  }
  50% {
    opacity: 1;
    transform: translate3d(0, -3.5*$scale, 0) scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -4.5*$scale, 0) scale3d(1, 1, 1);
  }
}

@keyframes animation-incorrect-explosion-skull-2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale3d(0.001, 0.001, 1);
  }
  12.5% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(0.2, 0.2, 1);
  }
  75% {
    opacity: 1;
    transform: translate3d(3.5*$scale, 2.5*$scale, 0) scale3d(0.6, 0.6, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(4.5*$scale, 3.5*$scale, 0) scale3d(0.1, 0.1, 1);
  }
}

@keyframes animation-incorrect-explosion-wheel-1 {
  0% {
    transform: translate3d(1.2*$scale, 0, 0) scale3d(0.001, 0.001, 1);
    opacity: 0;
  }
  60% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    opacity: 1;
  }
  80% {
    transform: translate3d(-0.6*$scale, 0, 0) scale3d(1.5, 1.5, 1);
    opacity: 1;
  }
  100% {
    transform: translate3d(-1.2*$scale, 0, 0) scale3d(2, 2, 1);
    opacity: 0;
  }
}

@keyframes animation-incorrect-explosion-wheel-2 {
  0% {
    transform: translate3d(0, 0, 0) scale3d(0.001, 0.001, 1);
    opacity: 0;
  }
  48.3333% {
    transform: translate3d(0, 0, 0) scale3d(0.3628, 0.3628, 1);
    opacity: 0;
  }
  66.6667% {
    transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1);
    opacity: 1;
  }
  100% {
    transform: translate3d(0.5*$scale, 0.5*$scale, 0) scale3d(1, 1, 1);
    opacity: 0;
  }
}

@keyframes animation-incorrect-explosion-wheel-3 {
  0% {
    transform: translate3d(0, 0, 0) scale3d(0.001, 0.001, 1);
    opacity: 0;
  }
  48.3333% {
    transform: translate3d(0, 0, 0) scale3d(0.3628, 0.3628, 1);
    opacity: 0;
  }
  66.6667% {
    transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1);
    opacity: 1;
  }
  100% {
    transform: translate3d(-0.5*$scale, 1.5*$scale, 0) scale3d(1, 1, 1);
    opacity: 0;
  }
}

.AnimationIncorrectExplosion {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 100;

  pointer-events: none;

  &.fixed {
    position: fixed;
  }

  &.visible {
    display: block;

    .animation-incorrect-explosion {
      animation-play-state: running;
    }
  }

  .animation-incorrect-explosion {
    display: inline-block;
    position: absolute;
    width: 4*$scale;
    height: 4*$scale;
    top: -2*$scale;
    left: -2*$scale;
    transform-style: preserve-3d;

    animation-delay: 0;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  .animation-incorrect-explosion-circle {
    animation-name: animation-incorrect-explosion-circle;
    animation-duration: 0.8*$durationScale;
  }

  .animation-incorrect-explosion-x-1 {
    animation-name: animation-incorrect-explosion-x-1;
    animation-duration: 0.5*$durationScale;
  }

  .animation-incorrect-explosion-x-2 {
    animation-name: animation-incorrect-explosion-x-2;
    animation-duration: 0.6*$durationScale;
    transform-origin: 2*$scale 2*$scale 0;
  }

  .animation-incorrect-explosion-x-3 {
    animation-name: animation-incorrect-explosion-x-3;
    animation-duration: 0.7*$durationScale;
  }

  .animation-incorrect-explosion-x-4 {
    animation-name: animation-incorrect-explosion-x-4;
    animation-duration: 0.5*$durationScale;
  }

  .animation-incorrect-explosion-skull-1 {
    animation-name: animation-incorrect-explosion-skull-1;
    animation-duration: 0.6*$durationScale;
  }

  .animation-incorrect-explosion-skull-2 {
    animation-name: animation-incorrect-explosion-skull-2;
    animation-duration: 0.8*$durationScale;
  }

  .animation-incorrect-explosion-wheel-1 {
    animation-name: animation-incorrect-explosion-wheel-1;
    animation-duration: 0.5*$durationScale;
  }

  .animation-incorrect-explosion-wheel-2 {
    animation-name: animation-incorrect-explosion-wheel-2;
    animation-duration: 0.6*$durationScale;
  }

  .animation-incorrect-explosion-wheel-3 {
    animation-name: animation-incorrect-explosion-wheel-3;
    animation-duration: 0.6*$durationScale;
  }
}
