@import '../css/common_vars';

.ExerciseComponent {
  .start-button {
    width: 25rem;
    transition: all 500ms ease-in-out;

    &.instructions-showing {
      background-color: shade($color_main, 25%);

      &:hover, &:active {
        background-color: shade($color_main, 10%);
      }
    }
  }

  .summary {
    margin-bottom: 1rem;

    h2 {
      font-size: 2rem;
    }

    .ranking {
      width: 100%;

      font-size: 2.25rem;
      color: $color_main;
      background-color: $color_bright;

      td {
        border: 0.25rem solid $color_main;
        padding: 0.5rem;
      }
    }

    .feedback {
      font-size: 2.25rem;
    }
  }

  .start-screen {
    flex-direction: column;

    .ExerciseInstructions {
      margin-bottom: 1rem;
    }
  }
}