@import "../../css/common_vars";

.Card {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;

  width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 500ms ease-in-out;

  & > * {
    transition: all 500ms ease-in-out;
  }

  &.blurred > * {
    filter: blur(8px);
  }

  &.opaque {
    opacity: 0;
  }

  &.clickable {
    cursor: pointer;
  }

  &.draggable {
    transition: opacity 500ms ease-in-out, background-color 500ms ease-in-out,
      color 500ms ease-in-out;
  }

  /*
   *
   *    Colors
   *
   */

  &.color-bright {
    color: $color_main;
    background-color: $color_bright;
  }
  &.color-main {
    color: white;
    background-color: $color_main;

    input,
    textarea {
      color: black;
    }
  }
  &.color-accent {
    background-color: $color_accent;
  }

  &.color-success {
    background-color: $color_success;
    color: white;
  }

  &.color-failure {
    background-color: $color_error;
    color: white;
  }

  a {
    color: $color_bright;

    &:hover {
      color: darken($color_bright, 5%);
    }
  }
}
