@import "themes/example/colors.scss";

$mq_lg: "screen and (min-width: 1220px) and (min-height: 750px)";
$mq_md: "screen and (min-width: 1020px) and (min-height: 600px)";
$mq_sm: "screen and (min-width: 820px) and (min-height: 450px)";

$lg_width: 1200px;
$lg_height: 750px;

$md_width: 1000px;
$md_height: 600px;

$sm_width: 800px;
$sm_height: 450px;

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

:export {
  main: $color_main;
  bright: $color_bright;
  clickable: $color_clickable;

  success: $color_success;
  error: $color_error;

  font: $main-font-family;
}

/* Main color: #0f75bc
 Accent color: #d91b5c */

//$color_background: #005EB8;
//
//$color_bright: #f1fcfd;
//$color_main: #13294B;
//$color_accent: #D05878;
//
//$color_highlight: rgb(255, 128, 0);
//
//$color_clickable: #A12D4C;
//$color_clickable_hover: lighten(#A12D4C, 15%);
//
$color_background: $color_background;

$color_bright: $color_bright;
$color_main: $color_main;

$color_highlight: $color_highlight;

$color_clickable: $color_clickable;
$color_accent: $color_accent;

$color_success: $color_success;
$color_error: $color_error;

$font-family: $main-font-family, sans-serif;
$color_clickable_hover: lighten($color_clickable, 15%);
