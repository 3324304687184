@import "css/common_vars";

.ExerciseHints {
  position: absolute;
  z-index: 10;

  background-color: $color_bright;

  width: 100%;
  box-sizing: border-box;

  left: 0;
  bottom: 4rem;
  height: 8rem;

  font-size: 1.5rem;
  font-weight: 700;

  opacity: 0;
  transform: scale(0);

  &.started {
    animation-name: hint-pop-out;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    opacity: 1;
    transform: scale(1);
  }

  .Button {
    padding: 2rem;
    font-size: 1.5rem;

    margin-left: 1rem;
  }
}

.ExerciseHints-overlay {
  border-radius: 10px;

  transition: all 500ms ease-in-out;
  animation-name: pulsate;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;

  position: fixed;
  background-color: $color_bright;
  box-shadow: 0 0 5px 5px $color_bright;
  opacity: 0.5;

  &.hidden {
    opacity: 0;
  }
}

.vertical {
  .ExerciseHints {
    font-size: 2rem;
    height: 12rem;
  }
}

@keyframes pulsate {
  from {opacity: 0}
  50% {opacity: 0.8}
  to {opacity: 0}
}

@keyframes hint-pop-out {
  from {transform: scale(0)}
  70% {transform: scale(1.2)}
  to {transform: scale(1)}
}