.MemoryCard {
  display: inline-block;
  padding: 0.5rem;
  transition: opacity 500ms ease-in-out;

  cursor: pointer;

  .back, .front {
    transition: opacity 500ms ease-in-out;
    background-position: center;
    background-repeat: no-repeat;

    width: 100%;
  }

  .back {
    box-sizing: border-box;
    padding: 1rem;
    background-size: contain;
    background-origin: content-box;

    opacity: 0.2;
  }

  .front {
    position: relative;

    opacity: 0;

    .text, .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .text {
      font-size: 1.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      z-index: 2;

      background-color: rgba(1, 1, 1, 0.5);
    }

    .image {
      transition: opacity 500ms ease-in-out;

      background-size: cover;
      z-index: 1;
      opacity: 0;
    }
  }

  &.revealed {
    cursor: default;

    .back {
      opacity: 0;
    }

    .front {
      opacity: 1;
    }
  }

  &.answered {
    .front {
      .image {
        opacity: 1;
      }
    }
  }
}