.Container {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  &.auto-height {
    height: auto;
    margin-top: 10rem;
  }
}